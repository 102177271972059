<template>
  <div class="contactus">
    <Head></Head>
    <div class="contactus-titles">
      <p class="contactus-title">{{ $store.state.staticName.ContactUs }}</p>
    </div>
    <div class="contactus-main">
      <div class="contactus-main-xian"></div>
      <div class="contactus-main-box">
        <div class="contactus-main-box-left">
          <div class="title">{{ $store.state.staticName.company }}</div>
          <div class="msg msg1">
            {{ $store.state.staticName.phone }}：400-689-8628
          </div>
          <div class="msg">{{ $store.state.staticName.company_address }}</div>
          <div class="msg">
            {{ $store.state.staticName.email }}：wuyuekeji201781@sina.com
          </div>
          <div class="msg">
            {{ $store.state.staticName.WeChat_Official_Account }}：午跃钛科技
          </div>
          <div class="msg">QQ：930338998</div>
        </div>
        <div class="contactus-main-box-right">
          <div class="img">
            <img class="img1" src="../assets/gongzhonggao.jpg" alt="" />
          </div>
          <div class="text">
            {{ $store.state.staticName.WeChat_Official_Account }}
          </div>
        </div>
      </div>
      <el-image
        style="width: 100%"
        :src="require('../assets/map.png')"
        :preview-src-list="[require('../assets/map.png')]"
      >
      </el-image>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from "@/components/Head";
import MyBaiduMap from "@/components/MyBaiduMap";
import Foot from "@/components/Foot";
export default {
  name: "ContactUs",
  components: {
    Head,
    MyBaiduMap,
    Foot,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.contactus {
  background-color: #fff;
  .contactus-titles {
    margin-top: 66px;
    text-align: center;

    .contactus-title {
      font-size: 50px;
      font-weight: 500;
      text-align: center;
      color: #1850af;
    }
  }
  .contactus-main {
    width: 1660px;
    margin: 19px auto 319px;
    //background-color: #999;
    .contactus-main-xian {
      width: 1258px;
      height: 1px;
      border: 1px solid #cccccc;
      margin: 0 auto;
    }
    .contactus-main-box {
      margin: 0 auto 80px;
      width: 1160px;
      display: flex;
      justify-content: space-between;
      //margin-top: 22px;
      //margin-left: 186px;
      //margin-right: 314px;
      .contactus-main-box-left {
        width: 300px;
        display: flex;
        flex-direction: column;

        .title {
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-top: 30px;

          font-size: 25px;
          font-weight: 400;
          text-align: left;
          color: #1850af;
        }

        .msg {
          white-space: nowrap;
          text-overflow: ellipsis;
          box-sizing: border-box;
          margin-top: 12px;
          padding-left: 25px;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #666666;
        }
        .msg1 {
          margin-top: 23px;
        }
      }
      .contactus-main-box-right {
        box-sizing: border-box;
        .img {
          margin-top: 36px;
          width: 176px;
          height: 176px;
          background: #fafafa;
          border-radius: 8px;
          border: 12px solid #1850af;
        }
        .text {
          margin-top: 22px;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #dd1212;
        }
      }
    }
  }
}
.img1 {
  width: 100%;
  height: 100%;
}
:deep(.el-carousel__container),
my-carousel {
  height: 580px !important;
}
:deep(.mymap div) {
  height: 560px !important;
}
</style>
